function pccAddClass(el, className) {
	if (el.classList) {
		el.classList.add(className);
	} else {
		el.className += ' ' + className;
	}
}
function pccRemoveClass(el, className) {
	if (el.classList) {
		el.classList.remove(className);
	} else {
		el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
	}
}

document.addEventListener('DOMContentLoaded', function () {

  var reasons = document.querySelectorAll('.reason');
  var reasonHeaders = document.querySelectorAll('.reason > h6');
  var reasonInfos = document.querySelectorAll('.reason-info');
  var reasonMores = document.querySelectorAll('.reason-more');
  var showingInfos = [];
  for (var x=0; x < reasons.length; x++) {
    (function(index) {
      reasonInfos[index].addEventListener('mouseenter', function(e) {
        while (showingInfos.length > 0) {
          var info = showingInfos.pop();
          pccRemoveClass(info, 'showing');
        }
        pccAddClass(reasons[index], 'showing');
        showingInfos.push(reasons[index]);
      });
      reasonHeaders[index].addEventListener('click', function(e) {
        while (showingInfos.length > 0) {
          var info = showingInfos.pop();
          pccRemoveClass(info, 'showing');
        }
        pccAddClass(reasons[index], 'showing');
        showingInfos.push(reasons[index]);
      });
      reasonMores[index].addEventListener('click', function(e) {
        while (showingInfos.length > 0) {
          var info = showingInfos.pop();
          pccRemoveClass(info, 'showing');
        }
      });
    })(x);
  }

});
